import React from 'react'
import PropTypes from 'prop-types'

const Cards = ({ items }) => {
  return (
    <div className="body-small">
      <main className="cards-container">
        {items.map((item) => {
          if (!item) {
            return <></>
          }
          return (
            <div className="custom-card">
              {item.image && (
                <div className="imageHolder">
                  <img
                    src={
                      !!item.image.source.childImageSharp
                        ? item.image.source.childImageSharp.fluid.src
                        : item.image.source
                    }
                    alt={item.image.alt}
                  />
                </div>
              )}
              <h3>
                <a href={item.link.url}>{item.link.title}</a>
              </h3>
              <p className="card-body">{item.description}</p>
            </div>
          )
        })}
      </main>
    </div>
  )
}

Cards.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
      description: PropTypes.string,
      image: PropTypes.shape({
        source: PropTypes.string,
        alt: PropTypes.string,
      }),
    })
  ),
}

export default Cards
