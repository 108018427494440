import React from 'react'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../Layout'
import Content, { HTMLContent } from '../Content'
import Cards from '../organisms/Cards/Cards'
import StayInTouchSection from '../organisms/StayInTouchSection/StayInTouchSection'

export const InfoPageTemplate = ({
  content,
  contentComponent,
  title,
  cards,
  stayInTouch,
}) => {
  const PostContent = contentComponent || Content
  const showContactUsForm =
    !isEmpty(stayInTouch) &&
    (stayInTouch.tag || stayInTouch.title || stayInTouch.description)

  return (
    <>
      <section className="section">
        <div className="container content">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                {title}
              </h1>
              <PostContent content={content} />
              <br />
              {cards && <Cards items={cards} />}
            </div>
          </div>
        </div>
      </section>
      {showContactUsForm && (
        <StayInTouchSection
          title={stayInTouch.title}
          description={stayInTouch.description}
          tag={stayInTouch.tag}
          emailUpdatesConsent={stayInTouch.emailUpdatesConsent}
        />
      )}
    </>
  )
}

InfoPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  stayInTouch: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    tag: PropTypes.string,
    emailUpdatesConsent: PropTypes.string,
  }),
}

const InfoPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <InfoPageTemplate
        content={post.html}
        cards={post.frontmatter.cards}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        stayInTouch={post.frontmatter.stayInTouch}
      />
    </Layout>
  )
}

InfoPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default InfoPage

export const pageQuery = graphql`
  query InfoPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        stayInTouch {
          title
          description
          emailUpdatesConsent
          tag
        }
        cards {
          link {
            title
            url
          }
          description
          image {
            source {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }
      }
    }
  }
`
